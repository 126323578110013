import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import { DayPickerRangeController } from 'react-dates'
import isBeforeDay from 'react-dates/lib/utils/isBeforeDay'
import Moment from 'moment'

import { PaginationArrowIcon as PaginationIcon } from 'icons/PaginationArrowIcon/PaginationArrowIcon'
import { TEXT_STYLES } from 'TEXT_STYLES'

const CalendarWrapper = styled.div`
  .CalendarMonthGrid_month__horizontal {
    width: 250px;
  }
  .CalendarDay__default {
    width: 36px !important;
    height: 35px !important;
  }
  .DayPicker_weekHeader_li {
    width: 36px !important;
  }
  .DayPicker_weekHeader_ul {
    margin-top: -7px;
    ${TEXT_STYLES.H4Inactive}
  }
  .CalendarMonth {
    padding: 0 !important;
  }
  .DayPicker_weekHeader {
    padding: 0 !important;
    margin-left: -10px !important;
  }
  .DayPicker_weekHeader:nth-child(even) {
    padding: 0 !important;
    margin-left: -37px !important;
  }
  .CalendarMonthGrid__horizontal {
    left: 0;
  }
  .CalendarMonthGrid_month__horizontal {
    left: 0;
    padding: 0;
  }
  .CalendarMonthGrid_month__horizontal:nth-child(odd) {
    margin-left: 22px;
  }
  .CalendarMonth_table td {
    border: 1px solid ${props => props.theme.colors.grey100};
  }
  .CalendarDay {
    ${TEXT_STYLES.H4Black}
  }
  .CalendarDay__blocked_calendar {
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.grey300};
    &.CalendarDay__selected_span {
      color: ${props => props.theme.colors.grey900};
    }
    &.CalendarDay__selected {
      color: ${props => props.theme.colors.grey900};
    }
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span {
    background-color: ${props => props.theme.colors.grey050};
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.grey100};
  }

  .CalendarDay__default:hover {
    background-color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.grey100};
    &.CalendarDay__blocked_calendar {
      color: ${props => props.theme.colors.grey300};
      background-color: ${props => props.theme.colors.white};
    }
    &.CalendarDay__selected_span {
      background-color: ${props => props.theme.colors.grey050};
      border-color: ${props => props.theme.colors.grey100};
      color: ${props => props.theme.colors.grey900};
    }
    &.CalendarDay__selected {
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.primary};
    }
  }

  /* Month name */
  .CalendarMonth_caption {
    ${TEXT_STYLES.H3Black}
  }

  .CalendarDay__blocked_out_of_range {
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.grey300};
    &:hover {
      background: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.grey300};
    }
  }
`

const arrowIconProps = css`
  position: absolute;
  top: 20px;
`

const ForwardIcon = styled(PaginationIcon)`
  ${arrowIconProps};
  right: 16%;
`

const BackwardIcon = styled(PaginationIcon)`
  ${arrowIconProps};
  left: 0;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  transition: opacity 0.1s ease;
  transform: rotate(180deg);
`

class Calendar extends Component {
  shouldComponentUpdate(nextProps) {
    // To improve react-dates loading time and to prevent
    // lag when using other components in the same container
    // as the Calendar, we ensure that the Calendar only
    // rerenders when the dates or the focusedInput is updated
    const { startDate, endDate, focusedInput } = this.props
    if (startDate !== nextProps.startDate) {
      return true
    }
    if (endDate !== nextProps.endDate) {
      return true
    }
    if (focusedInput !== nextProps.focusedInput) {
      return true
    }
    return false
  }

  checkDayBlocked = day => {
    const { blockPastDates, isDayBlocked } = this.props

    // if isDayBlocked is explicitly passed in use that
    if (isDayBlocked) {
      return isDayBlocked(day)
    }

    return blockPastDates && isBeforeDay(day, Moment())
  }

  render() {
    const {
      onDatesChange,
      onFocusChange,
      focusedInput,
      startDate,
      endDate,
      onPrevMonthClick,
      onNextMonthClick,
      classNames,
      className,
      minimumNights,
      numberOfMonths,
      orientation,
      navPrev,
      navNext,
      initialVisibleMonth,
      isOutsideRange,
      keepOpenOnDateSelect
    } = this.props

    return (
      <CalendarWrapper classNames={classNames} className={className}>
        <DayPickerRangeController
          onDatesChange={onDatesChange}
          onFocusChange={onFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          numberOfMonths={numberOfMonths}
          orientation={orientation}
          navPrev={navPrev}
          navNext={navNext}
          isDayBlocked={this.checkDayBlocked}
          isOutsideRange={isOutsideRange}
          onPrevMonthClick={onPrevMonthClick}
          onNextMonthClick={onNextMonthClick}
          noBorder
          hideKeyboardShortcutsPanel
          minimumNights={minimumNights !== undefined ? minimumNights : 1}
          initialVisibleMonth={initialVisibleMonth}
          keepOpenOnDateSelect={keepOpenOnDateSelect}
          renderMonthElement={({ month }) =>
            Moment(month)
              .locale('')
              .format('MMMM YYYY')
          }
        />
      </CalendarWrapper>
    )
  }
}

Calendar.defaultProps = {
  numberOfMonths: 2,
  orientation: undefined,
  className: undefined,
  focusedInput: undefined,
  keepOpenOnDateSelect: false,
  initialVisibleMonth: undefined,
  isOutsideRange: undefined,
  navPrev: <BackwardIcon />,
  navNext: <ForwardIcon />,
  classNames: '',
  startDate: Moment(),
  endDate: Moment(),
  blockPastDates: undefined,
  onPrevMonthClick: undefined,
  onNextMonthClick: undefined,
  minimumNights: undefined,
  isDayBlocked: undefined
}

Calendar.propTypes = {
  onDatesChange: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func.isRequired,
  focusedInput: PropTypes.string,
  startDate: PropTypes.objectOf(PropTypes.any),
  endDate: PropTypes.objectOf(PropTypes.any),
  blockPastDates: PropTypes.bool,
  onPrevMonthClick: PropTypes.func,
  onNextMonthClick: PropTypes.func,
  minimumNights: PropTypes.number,
  isDayBlocked: PropTypes.func,
  isOutsideRange: PropTypes.func,
  numberOfMonths: PropTypes.number,
  orientation: PropTypes.string,
  className: PropTypes.string,
  navPrev: PropTypes.element,
  navNext: PropTypes.element,
  initialVisibleMonth: PropTypes.func,
  keepOpenOnDateSelect: PropTypes.bool,
  classNames: PropTypes.string
}

export { Calendar }
