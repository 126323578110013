import React, {
  useRef,
  useEffect,
  useReducer,
  forwardRef,
  useCallback
} from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// TODO: This pollyfill could be loaded async only when dialog is not supported
const dialogPolyfill =
  typeof HTMLDialogElement === 'function'
    ? null
    : require('dialog-polyfill').default

const StyledDialog = styled.dialog`
  position: absolute;
  display: block;
  box-sizing: border-box;
  left: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  padding: 8px;
  margin: unset;
  border: 0px solid ${props => props.theme.colors.grey200};
  background: ${props => props.backgroundColor};

  :not([open]) {
    display: none;
  }
`

/*
 * This is a base component for creating Popovers, Poppers, Modals etc
 * Avoid adding an extra style or logic here
 * The purpose is providing a declarative way of using dialogs
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog
 */
export const Dialog = forwardRef((props, refProp) => {
  const { children, className, isOpen, isModal, backgroundColor } = props
  const dialogRef = useRef()
  const [updateCounter, forceUpdate] = useReducer(x => x + 1, 0)

  let container = document.getElementById('dialogs')

  // Creates a main container where all dialogs will be added
  if (!container) {
    container = document.createElement('div')
    // The dialogs container must have the highest z-index
    container.setAttribute(
      'style',
      'position: absolute; top: 0px; left: 0px; z-index: 999999'
    )
    container.setAttribute('id', 'dialogs')
    document.body.appendChild(container)
  }

  // When the dialog is a modal `showModal` needs to be called manually
  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLDialogElement/showModal
  useEffect(() => {
    const dialog = dialogRef.current

    if (!dialog || !isModal) {
      return
    }

    const isElementOpen = dialog.getAttribute('open') === ''
    const isAlreadyOpen = isOpen && isElementOpen
    const isAlreadyClosed = !isOpen && !isElementOpen

    if (isAlreadyOpen || isAlreadyClosed) {
      return
    }

    if (isOpen) {
      dialog.showModal()
    } else {
      dialog.close()
    }
  }, [isOpen, isModal, updateCounter])

  const handleRef = useCallback(
    ref => {
      if (!dialogRef.current) {
        dialogRef.current = ref

        if (dialogPolyfill) {
          dialogPolyfill.registerDialog(ref)
        }

        if (typeof refProp === 'function') {
          refProp(ref)
        } else if (refProp) {
          // eslint-disable-next-line no-param-reassign
          refProp.current = ref
        }

        forceUpdate()
      }
    },
    [refProp]
  )

  return createPortal(
    <StyledDialog
      className={className}
      backgroundColor={backgroundColor}
      open={isOpen && !isModal ? true : undefined}
      ref={handleRef}>
      {children}
    </StyledDialog>,
    container
  )
})

Dialog.defaultProps = {
  isModal: false,
  children: undefined,
  className: undefined,
  backgroundColor: 'white'
}

Dialog.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  isModal: PropTypes.bool,
  backgroundColor: PropTypes.string
}
