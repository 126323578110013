import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styled from 'styled-components'

import { COLORS } from 'COLORS'
import { TEXT_STYLES } from 'TEXT_STYLES'
import { media } from 'helpers/media_queries'

const borderProps = props => {
  const {
    noBorder,
    noBottomBorder,
    noTopBorder,
    noLeftBorder,
    noRightBorder,
    noMargin
  } = props

  return `
    ${noBorder ? 'margin-bottom: 0px;' : ''}
    ${noBottomBorder || noBorder ? 'border-bottom: none;' : ''}
    ${noTopBorder || noBorder ? 'border-top: none;' : ''}
    ${noRightBorder || noBorder ? media.desktop`border-right: none;` : ''}
    ${noLeftBorder || noBorder ? media.desktop`border-left: none;` : ''}
    ${noBorder && noMargin ? 'border: none;' : ''}
  `
}

const marginProps = props => (props.noMargin || props.sneakyMargin ? '0px' : '')

const getMarginTop = props => {
  if (props.sneakyMargin) {
    return '-1'
  }

  if (props.noMargin || props.noBorder) {
    return '0'
  }

  return '20'
}

const getPadding = props => {
  if (props.extraPadding) {
    return 30
  }
  if (props.smallerPadding) {
    return 5
  }
  if (props.noMargin) {
    return 0
  }
  return 15
}

const getFontStyle = props => {
  if (props.sectionHeader) {
    return TEXT_STYLES.H3Black
  }

  return (props.titleFont || props.header) && TEXT_STYLES.H2Black
}

const getHeight = props => {
  if (props.sectionHeader) {
    return '50px'
  }

  if (props.header) {
    return '55px'
  }

  if (props.fullHeight) {
    return '100%'
  }

  return 'auto'
}

const CardWrapper = styled.div`
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  display: ${props => (props.noFlex ? 'block' : 'flex')};
  align-items: ${props =>
    props.column || props.stretch ? 'stretch' : 'center'};
  margin: ${marginProps};
  border-radius: 1px;
  background-color: ${props => (props.transparent ? 'transparent' : '#fff')};
  border: solid 1px ${COLORS.grey100};
  padding: ${getPadding}px;
  margin-top: ${props => (props.noTopMargin ? '0' : getMarginTop(props))}px;
  ${props => (props.sectionHeader ? 'margin-bottom: 0;' : '')}
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  height: ${props => getHeight(props)};
  ${props => (props.header ? 'max-height: 55px;' : '')}
  ${borderProps}
  ${props => (props.center ? 'justify-content: center;' : '')}
  ${props => (props.noFlex ? 'display: block;' : '')}
  overflow-y: ${props => (props.scrollable ? 'scroll' : 'unset')};
  ${props =>
    props.relative
      ? `
        position: relative;
        min-height: 100px;
        `
      : ''}
  ${getFontStyle}
  ${props => props.flex1 && 'flex: 1;'}
`

const CardSection = props => {
  const { children, onClick, dataId, className } = props
  const classNames = cx('card-section', className)

  return (
    <CardWrapper
      {...props}
      data-id={dataId}
      className={classNames}
      onClick={e => onClick && onClick(e)}>
      {children}
    </CardWrapper>
  )
}

CardSection.propTypes = {
  /** What will be rendered inside the the component */
  children: PropTypes.node,
  /** Any extra classnames you want to add */
  className: PropTypes.string,
  /** Optional action to be executed */
  onClick: PropTypes.func,
  /** Adds the default header height to the component */
  header: PropTypes.bool,
  /** Adds full height to be used on sidebars and other types of scrollable containers */
  fullHeight: PropTypes.bool,
  /** Adds scroll behavior to be used on sidebars and other types of scrollable containers */
  scrollable: PropTypes.bool,
  /** The next props can be used to use pre defined border status, margin and padding */
  noBorder: PropTypes.bool,
  noBottomBorder: PropTypes.bool,
  noTopBorder: PropTypes.bool,
  noLeftBorder: PropTypes.bool,
  noRightBorder: PropTypes.bool,
  noMargin: PropTypes.bool,
  sneakyMargin: PropTypes.bool,
  noTopMargin: PropTypes.bool,
  extraPadding: PropTypes.bool,
  fullWidth: PropTypes.bool,
  smallerPadding: PropTypes.bool,
  transparent: PropTypes.bool,
  /** The next props can be used to define the content layout */
  noFlex: PropTypes.bool,
  flex1: PropTypes.bool,
  column: PropTypes.bool,
  center: PropTypes.bool,
  relative: PropTypes.bool,
  /** Default styling */
  titleFont: PropTypes.bool,
  sectionHeader: PropTypes.bool,
  dataId: PropTypes.string
}

CardSection.defaultProps = {
  children: <></>,
  className: '',
  onClick: () => {},
  header: false,
  fullHeight: false,
  scrollable: false,
  noBorder: false,
  noBottomBorder: false,
  noTopBorder: false,
  noLeftBorder: false,
  noRightBorder: false,
  noMargin: false,
  sneakyMargin: false,
  noTopMargin: false,
  extraPadding: false,
  fullWidth: false,
  smallerPadding: false,
  transparent: false,
  noFlex: false,
  flex1: false,
  column: false,
  center: false,
  relative: false,
  titleFont: false,
  sectionHeader: false,
  dataId: ''
}

export { CardSection }
