import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { CheckBoxIcon } from 'icons/CheckBoxIcon/CheckBoxIcon'
import { Tooltip } from '@junglescout/edna'

import './checkbox_group.sass'

const CheckBoxGroup = ({
  handleOnClick,
  checked,
  disabled,
  label,
  size,
  color,
  className,
  dataId,
  testId,
  partial,
  tooltipContent
}) => {
  const groupClass = cx(
    'checkbox-group',
    { 'checkbox-group--disabled': disabled },
    className
  )
  const labelClass = cx('checkbox-group__label', {
    'checkbox-group__label--selected': checked
  })
  const onClick = () => {
    return !disabled && handleOnClick()
  }

  return (
    <div
      data-id={dataId}
      className={groupClass}
      onClick={onClick}
      data-testid={testId}>
      <CheckBoxIcon
        checked={checked}
        disabled={disabled}
        width={size}
        height={size}
        color={color}
        partial={partial}
      />
      {tooltipContent ? (
        <Tooltip content={tooltipContent} side="right" size="240px">
          <div className={labelClass}>{label}</div>
        </Tooltip>
      ) : (
        <div className={labelClass}>{label}</div>
      )}
    </div>
  )
}

CheckBoxGroup.defaultProps = {
  disabled: false,
  testId: undefined,
  partial: false,
  label: undefined,
  checked: false,
  size: undefined,
  color: 'grey700',
  className: undefined,
  dataId: undefined,
  tooltipContent: undefined
}

CheckBoxGroup.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  dataId: PropTypes.string,
  testId: PropTypes.string,
  partial: PropTypes.bool,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

export { CheckBoxGroup }
