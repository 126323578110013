const getStartDateError = (t, params) => {
  const { dateFormat, minDate, maxDate, startDate, endDate } = params

  if (!startDate) {
    return null
  }

  if (startDate.isBefore(minDate, 'day')) {
    return t(
      'common:DatePicker.error.startDate.minDate',
      'The start date cannot be before {{minDate}}',
      {
        minDate: minDate.format(dateFormat)
      }
    )
  }

  if (startDate.isAfter(maxDate, 'day')) {
    return t(
      'common:DatePicker.error.startDate.maxDate',
      'The start date cannot be after {{maxDate}}',
      {
        maxDate: maxDate.format(dateFormat)
      }
    )
  }

  if (startDate.isAfter(endDate, 'day')) {
    return t(
      'common:DatePicker.error.startDate.endDate',
      'The start date cannot be after the end date'
    )
  }

  return null
}

const getEndDateError = (t, params) => {
  const {
    dateFormat,
    minDate,
    maxDate,
    startDate,
    endDate,
    customizedEndDateErrorsMessage
  } = params

  if (!endDate) {
    return null
  }

  if (endDate.isBefore(minDate, 'day')) {
    return t(
      'common:DatePicker.error.endDate.minDate',
      'The end date cannot be before {{minDate}}',
      {
        minDate: minDate.format(dateFormat)
      }
    )
  }

  if (endDate.isAfter(maxDate, 'day')) {
    return (
      customizedEndDateErrorsMessage ||
      t(
        'common:DatePicker.error.endDate.maxDate',
        'The end date cannot be after {{maxDate}}',
        {
          maxDate: maxDate.format(dateFormat)
        }
      )
    )
  }

  if (endDate.isBefore(startDate, 'day')) {
    return t(
      'common:DatePicker.error.endDate.startDate',
      'The end date cannot be before the start date'
    )
  }

  return null
}

export const getErrors = (t, params) => {
  return {
    startDateError: getStartDateError(t, params),
    endDateError: getEndDateError(t, params)
  }
}
