import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { DateRangePickerInputController } from 'react-dates'
import { TEXT_STYLES } from 'TEXT_STYLES'
import { Calendar } from 'ui_elements/Calendar/Calendar'
import { momentDateObjectType } from 'types/general'

export const DATE_FORMAT = 'MMM D, YYYY'

const Header = styled.header`
  justify-content: space-between;
  padding: 0 8px 12px;
`

const DateInputLabelsWrapper = styled.div`
  display: flex;
`

const DateInputsWrapper = styled.div`
  input {
    height: 36px;
  }
  .DateRangePickerInput {
    display: flex;
  }

  .DateRangePickerInput_arrow {
    display: none;
  }

  .DateInput {
    width: 100%;

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }

  input {
    font-family: 'Inter', sans-serif;
    ${TEXT_STYLES.BodyBlack}
    border-style: solid;
    border-width: 1px;
    border-radius: 2px;
    outline: 0;

    &:focus {
      border-color: ${props => props.theme.colors.primary};
    }
  }

  input[id='startDate']:not(:focus) {
    border-color: ${props =>
      props.startDateError
        ? props.theme.colors.red500
        : props.theme.colors.grey100};
  }

  input[id='endDate']:not(:focus) {
    border-color: ${props =>
      props.endDateError
        ? props.theme.colors.red500
        : props.theme.colors.grey100};
  }
`

const InputLabel = styled.label`
  ${TEXT_STYLES.H4Black}
  width: 100%;
  margin-bottom: 4px;

  &:first-child {
    margin-right: 8px;
  }

  &:last-child {
    margin-left: 8px;
  }
`

const WeekdaysWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.grey050};
  ${TEXT_STYLES.H4Black}
  height: 32px;
  color: ${props => props.theme.colors.grey500};
`

const WeekdaysList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;
`

const WeekdaysListItem = styled.li`
  width: 38px;
  text-align: center;
`

const CalendarWrapper = styled.div`
  height: 250px;
  @media screen and (max-height: 620px) {
    height: 150px;
  }
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: scroll;
`

const CalendarInner = styled.div`
  width: 300px;
  margin: 0 auto;
  overflow-x: hidden;
  overflow: visible;
`

const StyledCalendar = styled(Calendar)`
  margin-left: 10px;
  width: 300px;
  height: 300px;
  padding-bottom: 8px;

  .CalendarMonth_caption {
    padding-top: 8px;
    text-align: left;
    strong {
      ${TEXT_STYLES.H4Black}
    }
  }

  .DayPicker_weekHeader_ul {
    display: none;
  }

  .DayPicker_weekHeader__verticalScrollable {
    border-bottom: 0;
  }

  .CalendarMonthGrid__vertical_scrollable {
    overflow: visible;
  }

  .DayPicker_transitionContainer__verticalScrollable {
    padding-top: 0;
    margin-bottom: 8px;
    height: 100%;
    position: relative;
    overflow: visible;
  }

  .CalendarDay__default {
    width: 39px !important;
    height: 38px !important;
  }

  .DayPicker_weekHeader_li {
    width: 39px !important;
  }
`

export const DateRangePicker = ({
  dateFormat,
  startDate,
  endDate,
  initialVisibleMonth,
  minDate,
  maxDate,
  numberOfMonths,
  onStartDateChange,
  onEndDateChange,
  startDateError,
  endDateError
}) => {
  const { t } = useTranslation()
  const [focusedInput, setFocusedInput] = useState('startDate')

  const handleDateChange = (dates, isFromTextInput) => {
    const { startDate: selectedStartDate, endDate: selectedEndDate } = dates

    if (selectedStartDate) {
      onStartDateChange(selectedStartDate)
    }

    if (selectedEndDate) {
      onEndDateChange(selectedEndDate)
    }

    // Should reset if a range already exists
    if (!isFromTextInput && startDate && endDate) {
      const isStartDateValid =
        selectedStartDate &&
        !selectedStartDate.isSame(startDate) &&
        !selectedStartDate.isSame(endDate)

      const newDate = isStartDateValid ? selectedStartDate : selectedEndDate

      onStartDateChange(newDate)
      onEndDateChange(null)
      setFocusedInput('endDate')
    }
  }

  const visibleMonth = initialVisibleMonth && initialVisibleMonth()
  const isBeforeToday = visibleMonth && visibleMonth.isBefore(moment(), 'day')

  const checkScroll = useCallback(
    container => {
      // Scroll to the bottom if the initial date is in the past
      if (container && isBeforeToday) {
        container.scrollTop = container.scrollHeight
      }
    },
    [isBeforeToday]
  )

  const isDayBlocked = day => {
    return day.isBefore(minDate, 'day') || day.isAfter(maxDate, 'day')
  }

  return (
    <div>
      <Header>
        <DateInputLabelsWrapper>
          <InputLabel htmlFor="startDate">
            {t('common:DatePicker.startDate', 'Start Date')}
          </InputLabel>
          <InputLabel htmlFor="endDate">
            {t('common:DatePicker.endDate', 'End Date')}
          </InputLabel>
        </DateInputLabelsWrapper>
        <DateInputsWrapper
          startDateError={startDateError}
          endDateError={endDateError}>
          <DateRangePickerInputController
            startDate={startDate}
            endDate={endDate}
            isStartDateFocused={focusedInput === 'startDate'}
            isEndDateFocused={focusedInput === 'endDate'}
            isOutsideRange={() => false}
            displayFormat={dateFormat}
            small
            noBorder
            showCaret={false}
            customArrowIcon={<div />}
            onDatesChange={dates => handleDateChange(dates, true)}
            keepOpenOnDateSelect
          />
        </DateInputsWrapper>
      </Header>
      <WeekdaysWrapper>
        <WeekdaysList>
          {moment.weekdaysMin(true).map(day => (
            <WeekdaysListItem key={day}>{day}</WeekdaysListItem>
          ))}
        </WeekdaysList>
      </WeekdaysWrapper>
      <CalendarWrapper ref={checkScroll}>
        <CalendarInner>
          <StyledCalendar
            monthFormat={dateFormat}
            startDate={startDate}
            endDate={endDate}
            onDatesChange={handleDateChange}
            onFocusChange={setFocusedInput}
            keepOpenOnDateSelect
            focusedInput={focusedInput}
            minimumNights={0}
            initialVisibleMonth={initialVisibleMonth}
            numberOfMonths={numberOfMonths}
            orientation="verticalScrollable"
            isDayBlocked={isDayBlocked}
            isOutsideRange={isDayBlocked}
            navPrev={<></>}
            navNext={<></>}
          />
        </CalendarInner>
      </CalendarWrapper>
    </div>
  )
}

DateRangePicker.defaultProps = {
  startDate: undefined,
  endDate: undefined,
  initialVisibleMonth: undefined,
  dateFormat: DATE_FORMAT,
  numberOfMonths: undefined,
  startDateError: undefined,
  endDateError: undefined
}

DateRangePicker.propTypes = {
  dateFormat: PropTypes.string,
  startDate: momentDateObjectType,
  endDate: momentDateObjectType,
  initialVisibleMonth: PropTypes.func,
  minDate: momentDateObjectType.isRequired,
  maxDate: momentDateObjectType.isRequired,
  numberOfMonths: PropTypes.number,
  onStartDateChange: PropTypes.func.isRequired,
  onEndDateChange: PropTypes.func.isRequired,
  startDateError: PropTypes.string,
  endDateError: PropTypes.string
}
